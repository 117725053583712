import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";
import InformationModal from "../../../InformationModal";
import CraneConfigCapacityModal from "./CraneConfigCapacityModal";
import CraneConfigManufacturerModal from "./CraneConfigManufacturerModal";
import Loader from "../../../Loader";
import CraneOptionsSelect from "./CraneOptionsSelect";

const OTHER_MANUFACTURER_VALUE = "OTHER_MANUFACTURER";
const OTHER_CAPACITY_VALUE = "OTHER_CAPACITY";
const HOIST_MODE = "Hoist";

const CraneConfigCreateHoistModal = ({
  hoistToEdit = null,
  onSubmit,
  onClose,
  hoistTypes = [],
  hoistManufacturers = [],
  setRefreshManufacturers,
  hoistCapacities = [],
  setRefreshCapacities,
}) => {
  const [loading, setLoading] = useState(false);
  const [hoist, setHoist] = useState(hoistToEdit || {});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createCapacityModal, setCreateCapacityModal] = useState(false);
  const [createManufacturerModal, setCreateManufacturerModal] = useState(false);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      onSubmit(hoist);
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const isValidManufacturer = (name) => {
    return !hoistManufacturers.find(
      (manufacturer) => manufacturer.name === name
    );
  };

  const isValidCapacity = (name) => {
    return !hoistCapacities.find((capacity) => capacity.name === name);
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : createCapacityModal ? (
    <CraneConfigCapacityModal
      mode={HOIST_MODE}
      isValid={isValidCapacity}
      onClose={() => setCreateCapacityModal(false)}
      onCreated={(createdCapacity) => {
        setCreateCapacityModal(false);
        setHoist({
          ...hoist,
          capacityId: createdCapacity?.id,
          capacityName: createdCapacity?.name,
        });
        setRefreshCapacities(true);
      }}
    />
  ) : createManufacturerModal ? (
    <CraneConfigManufacturerModal
      mode={HOIST_MODE}
      isValid={isValidManufacturer}
      onClose={() => setCreateManufacturerModal(false)}
      onCreated={(createdManufacturer) => {
        setCreateManufacturerModal(false);
        setHoist({
          ...hoist,
          manufacturerId: createdManufacturer?.id,
          manufacturerName: createdManufacturer?.name,
        });
        setRefreshManufacturers(true);
      }}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>{`${
        hoistToEdit ? "Edit" : "Add"
      } Hoist`}</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-left">
          <FormGroup className="d-flex flex-column">
            <div className="d-flex flex-grow-1 justify-content-start">
              <div className="col-6 pl-0">
                <Button
                  onClick={() =>
                    setHoist({
                      ...hoist,
                      withTrolley: true,
                    })
                  }
                  color={hoist.withTrolley ? "primary" : "white"}
                  className={`col-12 outline-none ${
                    hoist.withTrolley
                      ? "border-white bg-primary text-white"
                      : "border-primary bg-white text-primary"
                  }`}
                >
                  With Trolley
                </Button>
              </div>
              <div className="col-6 pr-0">
                <Button
                  onClick={() =>
                    setHoist({
                      ...hoist,
                      withTrolley: false,
                    })
                  }
                  color={!hoist.withTrolley ? "primary" : "white"}
                  className={`col-12 outline-none ${
                    !hoist.withTrolley
                      ? "border-white bg-primary text-white"
                      : "border-primary bg-white text-primary"
                  }`}
                >
                  Without Trolley
                </Button>
              </div>
            </div>
          </FormGroup>
          <FormGroup className="d-flex flex-column align-items-start">
            <Label for="craneHoistType">
              <span>Hoist Type</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <CustomInput
              id="craneHoistType"
              required={true}
              type="select"
              name="craneHoistType"
              value={hoist.craneHoistTypeId || ""}
              onChange={(e) => {
                const selectedHoistType = hoistTypes.find(
                  (hoistType) => hoistType.id === e.currentTarget.value
                );
                setHoist({
                  ...hoist,
                  craneHoistTypeId: e.currentTarget.value,
                  craneHoistTypeName: selectedHoistType
                    ? selectedHoistType.name
                    : "",
                });
              }}
            >
              <option value="">Select a hoist type...</option>
              {hoistTypes.map((hoistType) => (
                <option key={hoistType.id} value={hoistType.id}>
                  {hoistType.name}
                </option>
              ))}
            </CustomInput>
          </FormGroup>
          <FormGroup className="d-flex flex-column align-items-start">
            <Label for="craneHoistManufacturer">
              <span>Manufacturer</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <CraneOptionsSelect
              craneOptions={hoistManufacturers}
              isRequired={true}
              placeholder="hoist manufacturer"
              name="craneHoistManufacturer"
              value={hoist.manufacturerId || ""}
              onChange={(newValue) => {
                if (newValue === OTHER_MANUFACTURER_VALUE) {
                  setCreateManufacturerModal(true);
                } else {
                  const selected = hoistManufacturers.find(
                    (m) => m.id === newValue
                  );
                  setHoist({
                    ...hoist,
                    manufacturerId: selected?.id,
                    manufacturerName: selected ? selected.name : "",
                  });
                }
              }}
            />
          </FormGroup>
          <FormGroup className="d-flex flex-column align-items-start">
            <Label for="craneHoistCapacity">
              <span>Capacity</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <CraneOptionsSelect
              craneOptions={hoistCapacities}
              isRequired={true}
              placeholder="hoist capacity"
              name="craneHoistCapacity"
              value={hoist.capacityId || ""}
              onChange={(newValue) => {
                if (newValue === OTHER_CAPACITY_VALUE) {
                  setCreateCapacityModal(true);
                } else {
                  const selected = hoistCapacities.find(
                    (c) => c.id === newValue
                  );
                  setHoist({
                    ...hoist,
                    capacityId: selected?.id,
                    capacityName: selected ? selected.name : "",
                  });
                }
              }}
            />
          </FormGroup>
          <FormGroup className="d-flex flex-column align-items-start">
            <Label for="model">
              <span>Model</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              placeholder="Enter model"
              required={true}
              type="text"
              name="model"
              value={hoist.model || ""}
              onChange={(e) =>
                setHoist({
                  ...hoist,
                  model: e.currentTarget.value,
                })
              }
              maxLength={100}
            />
          </FormGroup>
          <FormGroup className="d-flex flex-column align-items-start">
            <Label for="serialNumber">
              <span>Serial Number</span>
              <span className="text-danger ml-1">*</span>
            </Label>
            <Input
              placeholder="Enter serial number"
              required={true}
              type="text"
              name="serialNumber"
              value={hoist.serialNumber || ""}
              onChange={(e) =>
                setHoist({
                  ...hoist,
                  serialNumber: e.currentTarget.value,
                })
              }
              maxLength={100}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button
            className="d-flex align-items-center ml-2"
            color="secondary"
            onClick={onClose}
          >
            Discard
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              className="d-flex align-items-center"
              color="primary"
              type="submit"
            >
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CraneConfigCreateHoistModal;

import React, { useEffect, useState } from "react";

import { FormGroup } from "reactstrap";
import { employeesApi } from "../../services/employeeServices";
import Label from "reactstrap/lib/Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox, faTools } from "@fortawesome/free-solid-svg-icons";
import InformationModal from "../InformationModal";
import { useAuth } from "../../providers/authProvider";
import SelectWrapper from "../SelectWrapper";

const PAGE_SIZE = 30;
const ROLE_TECHNICIAN = 3;

const MODE_LEAD = 1;
const MODE_DEFAULT = 2;
const MODE_NEW = 3;
const MODE_OTHER = 4;

const EmployeeSelector = ({ employee, setEmployee, mode }) => {
  const [authContext] = useAuth();
  const [employees, setEmployees] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await employeesApi.getEmployees({
          isActive: true,
          sortBy: "firstName",
        });
        const nonLeadEmployees = result.data.filter(
          (employee) => !employee.isLead
        );
        setEmployees(nonLeadEmployees);
      } catch (err) {
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error getting the employees list.",
        });
      }
    };
    if (!employees.length) {
      fetchData();
    }
  }, [employees]);

  const onEmployeeChange = (employee) => {
    if (employee) {
      setEmployee(employee);
    } else {
      setEmployee(null);
    }
  };

  return (
    <FormGroup className="d-flex justify-content-between align-items-center">
      <Label
        className="text-left col-4 mb-0 d-flex align-items-center pl-0"
        for="name"
      >
        <FontAwesomeIcon
          icon={mode === MODE_LEAD ? faToolbox : faTools}
          className={`mr-2 text-${
            mode === MODE_LEAD ? "primary" : "secondary"
          }`}
          style={{ paddingBottom: "1px" }}
        />
        <div className="d-flex align-items-center">
          {mode === MODE_LEAD
            ? "Lead"
            : mode === MODE_DEFAULT
            ? "Technician"
            : mode === MODE_NEW
            ? "New"
            : mode === MODE_OTHER
            ? "Other Time"
            : ""}
        </div>
      </Label>

      <SelectWrapper
        key={`employee-selector`}
        className="ml-2"
        entity="employee"
        filterItemFunction={(eop) =>
          eop.isActive || (employee && !eop.isActive && eop.id === employee.id)
        }
        formatItemFunction={(e) => {
          const employeeName = e.name || `${e.firstName} ${e.lastName}`;
          const status = !e.isActive ? " (Inactive)" : "";
          return {
            label: `${employeeName}${status}`,
            value: e.id,
          };
        }}
        fetchFunction={employeesApi.getEmployees}
        fetchParameters={{
          roleId: ROLE_TECHNICIAN,
          pageSize: PAGE_SIZE,
          serviceLocationId: authContext.serviceLocationId,
          sortBy: "firstName",
        }}
        defaultSelected={employee}
        onSelected={onEmployeeChange}
        title="techSelectorEmployee"
      />
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </FormGroup>
  );
};

export default EmployeeSelector;

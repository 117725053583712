import React, { useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { pdfjs } from "react-pdf";
import InformationModal from "../../../../components/InformationModal";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";
import CraneName from "../../cranes/CraneName";
import WorkPerformedReport from "./WorkPerformedReport";
import Loader from "../../../Loader";
import moment from "moment";
import ConfirmationModalWithInput from "../../../ConfirmationModalWithInput";
import { reportsApi } from "../../../../services/reportsServices";
import { useSidebar } from "../../../../providers/sidebarProvider";
import { useAuth } from "../../../../providers/authProvider";

const WORK_PERFORMED_REPORT_TAB = "WORK_PERFORMED_REPORT_TAB";
const TYPE_INSPECTION = "Inspection";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WorkOrderReports = () => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [, setSidebarContext] = useSidebar();

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [loading, setLoading] = useState();

  const [activeTab, setActiveTab] = useState(WORK_PERFORMED_REPORT_TAB);

  const onDownloadAll = async () => {
    return setConfirmationModal({
      size: "sm",
      isOpen: true,
      confirmInputPrepopulate: authContext.currentUser.email,
      onSubmit: async (email) => {
        setLoading(true);
        try {
          await reportsApi.workOrderDownloadAllPdf({
            workOrderId: workOrderDetails.workOrder.id,
            email,
            browserTimeOffset: moment().utcOffset() / 60,
          });
          setLoading(false);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Generate all reports",
            body: "You will receive an email with the reports once your request is processed.",
            onClose: () => {
              setInformationModal({
                rawBody: false,
                isOpen: false,
                title: "",
                body: "",
              });
              setSidebarContext({ isBatchModalOpen: true });
            },
          });
        } catch (err) {
          setLoading(false);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Generate all reports",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => setConfirmationModal(initConfirmationModal),
      confirmTextType: "email",
      confirmButtonText: "Confirm",
      confirmTextPlaceholder: "Enter email",
      title: "Generate all reports",
      rawBody: true,
      body: (
        <>
          <p>
            When your reports are ready, you can download them by clicking "See
            Generation Status," or through the notification that appears in the
            bottom-right corner of your screen.
          </p>
          <p>
            You'll also receive your reports by email once they finish
            generating.
          </p>
        </>
      ),
      confirmColor: "success",
    });
  };

  const workOrderCranes = workOrderDetails.workOrder.workOrderCranes.filter(
    (woc) => woc.craneInspections?.length
  );

  return (
    <Row className="mt-3">
      <Col sm="12" className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-start">
          <h4 className="mb-0">Reports</h4>
          <div className="d-flex justify-content-center align-items-center">
            {loading ? (
              <Loader size="sm" align="end" className="px-0 my-0" />
            ) : workOrderDetails.workOrder.jobType === TYPE_INSPECTION &&
              workOrderCranes.length ? (
              <>
                <Button
                  size="sm"
                  className="rounded"
                  onClick={() => setSidebarContext({ isBatchModalOpen: true })}
                >
                  View Generation Progress
                </Button>
                <Button
                  color="success"
                  size="sm"
                  className="rounded ml-2"
                  onClick={() => {
                    onDownloadAll();
                  }}
                >
                  <span className="font-500">Download All</span>
                  <FontAwesomeIcon icon={faDownload} className="ml-1" />
                </Button>
              </>
            ) : null}
          </div>
        </div>
        <div className="tab tab-vertical h-100 mt-2">
          <Nav
            tabs
            className="cursor-pointer border-bottom"
            style={{ marginRight: -1, zIndex: 1, position: "relative" }}
          >
            <NavItem>
              <NavLink
                className={`border-left border-top border-bottom ${
                  activeTab === WORK_PERFORMED_REPORT_TAB
                    ? "active bg-lighter-tabs font-weight-bold"
                    : ""
                }`}
                onClick={() => {
                  if (activeTab !== WORK_PERFORMED_REPORT_TAB)
                    setActiveTab(WORK_PERFORMED_REPORT_TAB);
                }}
              >
                <div className="d-flex align-items-center">
                  {workOrderDetails.workOrder.jobType} Ticket
                </div>
              </NavLink>
            </NavItem>
            {workOrderDetails.workOrder.jobType === TYPE_INSPECTION
              ? workOrderCranes.map((workOrderCrane) => {
                  const tabId = `crane-${workOrderCrane.craneId}`;
                  return (
                    <NavItem key={workOrderCrane.craneId}>
                      <NavLink
                        className={`border-left border-top border-bottom ${
                          activeTab === tabId
                            ? "active bg-lighter-tabs font-weight-bold"
                            : ""
                        }`}
                        onClick={() => {
                          if (activeTab !== tabId) setActiveTab(tabId);
                        }}
                      >
                        <CraneName crane={workOrderCrane.crane} />
                      </NavLink>
                    </NavItem>
                  );
                })
              : null}
          </Nav>
          <TabContent activeTab={activeTab} className="h-100 p-0 border">
            <TabPane tabId={WORK_PERFORMED_REPORT_TAB}>
              <Card className="border-radius-0 box-shadow-none d-flex flex-column flex-grow-1 bg-lighter-tabs mb-0 h-100">
                <CardBody>
                  {activeTab === WORK_PERFORMED_REPORT_TAB ? (
                    <WorkPerformedReport />
                  ) : null}
                </CardBody>
              </Card>
            </TabPane>
            {workOrderDetails.workOrder.jobType === TYPE_INSPECTION
              ? workOrderCranes.map((workOrderCrane, i) => {
                  const tabId = `crane-${workOrderCrane.craneId}`;
                  return (
                    <TabPane key={i} tabId={tabId}>
                      <Card className="border-radius-0 box-shadow-none d-flex flex-column flex-grow-1 bg-lighter-tabs mb-0 h-100">
                        <CardBody>
                          {activeTab === tabId ? (
                            <WorkPerformedReport
                              workOrderCrane={workOrderCrane}
                            />
                          ) : null}
                        </CardBody>
                      </Card>
                    </TabPane>
                  );
                })
              : null}
          </TabContent>
        </div>
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  rawBody: false,
                  isOpen: false,
                  title: "",
                  body: "",
                })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModalWithInput {...confirmationModal} />
      ) : null}
    </Row>
  );
};
export default WorkOrderReports;

import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import InformationModal from "../../../../components/InformationModal";
import Loader from "../../../../components/Loader";
import Select from "react-select";

const AddBranchContactInfo = ({
  submitFunction,
  onSubmit,
  onClose,
  serviceLocation = null,
  serviceLocations = [],
}) => {
  const [contactPhone, setContactPhone] = useState(
    serviceLocation?.contactPhone || ""
  );
  const [currentLocation, setCurrentLocation] = useState(
    serviceLocation || null
  );
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [loading, setLoading] = useState(false);
  const isEditing = Boolean(serviceLocation?.id);

  const filteredServiceLocations = serviceLocations.filter((sl) => {
    if (isEditing) {
      if (sl.id === serviceLocation.id) return true;
      return !sl.contactPhone;
    } else {
      return !sl.contactPhone;
    }
  });

  const options = filteredServiceLocations.map((loc) => ({
    value: loc.id,
    label: loc.name,
  }));

  const selectedOption = currentLocation
    ? { value: currentLocation.id, label: currentLocation.name }
    : null;

  const formatPhoneNumber = (value) => {
    const numbers = value.replace(/\D/g, "");

    if (numbers.length <= 3) {
      return numbers;
    } else if (numbers.length <= 6) {
      return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`;
    } else {
      return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(
        6,
        10
      )}`;
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    const formattedNumber = formatPhoneNumber(value);
    if (formattedNumber.length <= 14) {
      setContactPhone(formattedNumber);
    }
  };

  const handleChange = (e) => {
    if (!e) {
      setCurrentLocation(null);
    } else {
      const serviceLocation = serviceLocations.find((sl) => sl.id === e.value);
      setCurrentLocation(serviceLocation);
    }
  };

  const isValid = contactPhone.length === 14;

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!currentLocation) {
      return setInformationModal({
        isOpen: true,
        title: "Branch Contact Info",
        body: "Please select a service location",
      });
    }
    setLoading(true);
    try {
      await submitFunction({
        contactPhone,
        regionId: currentLocation?.regionId,
        id: currentLocation.id,
      });
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Branch Contact Info",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {serviceLocation
            ? "Edit Branch Contact Info"
            : "Add Branch Contact Info"}
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label for="email">
                    <span>Phone Number</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    type="tel"
                    id="contactPhone"
                    value={contactPhone}
                    onChange={handlePhoneChange}
                    placeholder="(123) 456-7890"
                    maxLength={14}
                    valid={isValid}
                    invalid={contactPhone.length > 0 && !isValid}
                  />
                  <FormFeedback valid>
                    Valid US phone number format!
                  </FormFeedback>
                  <FormFeedback invalid>
                    Please enter a complete US phone number
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label for="email">
                    <span>Service Location</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    isSearchable
                    placeholder="Search for a service location"
                    isClearable
                    required={true}
                    isDisabled={serviceLocation ? true : false}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color={"primary"}
                type="submit"
                disabled={!isValid || !currentLocation}
              >
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddBranchContactInfo;

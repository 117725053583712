import {
  faChevronLeft,
  faChevronRight,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Table } from "reactstrap";
import Button from "reactstrap/lib/Button";
import { reportsApi } from "../../../services/reportsServices";
import moment from "moment";
import { useAuth } from "../../../providers/authProvider";
import InformationModal from "../../../components/InformationModal";
import Loader from "../../../components/Loader";
import Papa from "papaparse";
import { utils } from "../../../utils/utils";

const PayCorReport = () => {
  const [authContext] = useAuth();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const previousMonday = () => setMonday(moment(monday).add(-1, "week"));

  const nextMonday = () => setMonday(moment(monday).add(1, "week"));

  const getSunday = () => moment(monday).endOf("isoWeek");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    reportsApi
      .payCorReport({
        weekStart: monday.format("YYYY-MM-DD"),
        serviceLocationId: authContext.serviceLocationId,
      })
      .then((result) => {
        setLoading(false);
        setData(result);
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      });
  }, [monday, authContext.serviceLocationId]);

  const totalOtherHour = (entryReg, entryOt) => {
    const total = (parseFloat(entryReg) + parseFloat(entryOt)).toFixed(2);
    return total;
  };

  const shouldShowLunchTime =
    data.length && data.some((entry) => entry.IS_CALIFORNIA_PAYROLL);

  const onExportCSV = () => {
    const csvData = data.flatMap((entry) => {
      if (entry.jobNumber.length) {
        return entry.jobNumber.map((jobNumber) => ({
          "Employee Id": entry.employeeId,
          "Employee Name": entry.employeeName,
          Date: utils.formatDate(entry.date, "MM/DD/YYYY"),
          Department: entry.departments[jobNumber],
          PTO: totalOtherHour(entry.PTO.reg, entry.PTO.ot),
          Bereavement: totalOtherHour(entry.BER.reg, entry.BER.ot),
          "Jury Duty": totalOtherHour(entry.JURY.reg, entry.JURY.ot),
          Training: totalOtherHour(entry.TRAINING.reg, entry.TRAINING.ot),
          ...(shouldShowLunchTime
            ? { Lunch: totalOtherHour(entry.LUNCH.reg, entry.LUNCH.ot) }
            : {}),
        }));
      } else {
        return [
          {
            "Employee Id": entry.employeeId,
            "Employee Name": entry.employeeName,
            Date: utils.formatDate(entry.date, "MM/DD/YYYY"),
            Department: entry.department || "",
            PTO: totalOtherHour(entry.PTO.reg, entry.PTO.ot),
            Bereavement: totalOtherHour(entry.BER.reg, entry.BER.ot),
            "Jury Duty": totalOtherHour(entry.JURY.reg, entry.JURY.ot),
            Training: totalOtherHour(entry.TRAINING.reg, entry.TRAINING.ot),
            ...(shouldShowLunchTime
              ? { Lunch: totalOtherHour(entry.LUNCH.reg, entry.LUNCH.ot) }
              : {}),
          },
        ];
      }
    });
    const CSVDownloadData = Papa.unparse(csvData, { delimiter: ";" });
    const filename = `PayCorReport_${monday.format("MM/DD/YYYY")}`;
    const hiddenElement = document.createElement("a");
    hiddenElement.href =
      "data:text/csv; charset=utf-8,\ufeff" +
      encodeURIComponent(CSVDownloadData);
    hiddenElement.target = "_self";
    hiddenElement.download = `${filename}.csv`;
    hiddenElement.click();
  };

  const renderDataRow = (
    entry,
    jobNumber,
    index,
    shouldShowLunchTime = false
  ) => (
    <tr key={`${jobNumber}-${index}`}>
      <td className="text-truncate">{entry.employeeId || "-"}</td>
      <td className="text-truncate">{entry.employeeName}</td>
      <td className="text-truncate">
        {utils.formatDate(entry.date, "MM/DD/YYYY")}
      </td>
      <td className="text-truncate">
        {entry.departments[jobNumber] || entry.department || "-"}
      </td>
      <td className="text-truncate">
        {totalOtherHour(entry.PTO.reg, entry.PTO.ot)}
      </td>
      <td className="text-truncate">
        {totalOtherHour(entry.BER.reg, entry.BER.ot)}
      </td>
      <td className="text-truncate">
        {totalOtherHour(entry.JURY.reg, entry.JURY.ot)}
      </td>
      <td className="text-truncate">
        {totalOtherHour(entry.TRAINING.reg, entry.TRAINING.ot)}
      </td>
      {shouldShowLunchTime ? (
        <td className="text-truncate">
          {entry.IS_CALIFORNIA_PAYROLL
            ? totalOtherHour(entry.LUNCH.reg, entry.LUNCH.ot)
            : "-"}
        </td>
      ) : null}
    </tr>
  );

  return (
    <Container fluid>
      <Card className="col-12 px-0">
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">PayCor Report</h3>
            <small className="text-muted ml-2 pt-1">({data.length})</small>
          </div>
          <div className="d-flex justify-content-between">
            <Button className="my-1" onClick={previousMonday}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <strong className="my-1 mx-1 p-2 border rounded">
              {monday.format("MM/DD/YYYY")}
              {" - "}
              {getSunday().format("MM/DD/YYYY")}
            </strong>
            <Button
              className="my-1"
              onClick={nextMonday}
              disabled={getSunday().isAfter(moment())}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
          <Button
            color={"primary"}
            onClick={onExportCSV}
            disabled={!data?.length}
            className="ml-2 rounded"
            size="sm"
          >
            <FontAwesomeIcon icon={faFileCsv} className="mr-2" />
            Download
          </Button>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Table className="mb-0">
              <thead className="small text-muted">
                <tr>
                  <th className="border-top-0">Employee Id</th>
                  <th className="border-top-0">Employee Name</th>
                  <th className="border-top-0">Date</th>
                  <th className="border-top-0">Department</th>
                  <th className="border-top-0">PTO</th>
                  <th className="border-top-0">Bereavement</th>
                  <th className="border-top-0">Jury Duty</th>
                  <th className="border-top-0">Training</th>
                  {shouldShowLunchTime ? (
                    <th className="border-top-0">Lunch</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((entry, dataIndex) =>
                    entry.jobNumber.length
                      ? entry.jobNumber.map((jobNumber, jobIndex) =>
                          renderDataRow(
                            entry,
                            jobNumber,
                            jobIndex,
                            shouldShowLunchTime
                          )
                        )
                      : renderDataRow(
                          entry,
                          "-",
                          dataIndex,
                          shouldShowLunchTime
                        )
                  )
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center text-muted small">
                      No data has been recorded
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default PayCorReport;

import React, { useEffect, useState } from "react";
import config from "../../../../config/config";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useSidebar } from "../../../../providers/sidebarProvider";
import { useAuth } from "../../../../providers/authProvider";

export const toastConfig = {
  toastId: "loading",
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  style: {
    width: "auto",
  },
};

const getWaitingJobs = ({ data }) => {
  const waitingJobs = data.waitingJobs
    .sort((x, y) => (x.jobId < y.jobId ? -1 : 1))
    .reduce((p, c, i) => {
      const log = `#${c.jobNo} - ${c.email}`;
      return {
        ...p,
        [c.jobId]: {
          log,
          status: "WAITING",
          position: i + 1,
          email: c.email,
          jobNo: c.jobNo,
          jobId: c.jobId,
          userId: c.userId,
        },
      };
    }, {});
  return waitingJobs;
};

const getActiveJobs = ({ data }) => {
  const inProgressJobs = (data.inProgressJobs || [])
    .sort((x, y) => (x.jobId < y.jobId ? -1 : 1))
    .reduce((p, c, i) => {
      const log = `#${c.jobNo} - ${c.email}`;
      return {
        ...p,
        [c.jobId]: {
          log,
          status: "IN_PROGRESS",
          email: c.email,
          progress: c.progress
            ? `${parseInt(c.progress)}%`
            : "Calculating progress..",
          jobNo: c.jobNo,
          jobId: c.jobId,
          userId: c.userId,
        },
      };
    }, {});
  return inProgressJobs;
};

const BatchPDFGenerationSSE = () => {
  const [authContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  const [logs, setLogs] = useState({});

  useEffect(() => {
    // 1. Create the EventSource connection pointing to your SSE endpoint
    const eventSource = new EventSource(
      `${config.apiURL}api/work-orders/download-all-pdf-events`,
      { withCredentials: true }
    );

    // 2. Listen for SSE events
    eventSource.addEventListener("job-progress", (e) => {
      const data = JSON.parse(e.data);
      const waitingJobs = getWaitingJobs({ data, authContext });
      const log = `#${data.jobNo} - ${data.email}`;
      const logs = {
        [data.jobId]: {
          log,
          status: "IN_PROGRESS",
          progress: data.progress
            ? `${parseInt(data.progress)}%`
            : "Calculating progress..",
        },
        ...waitingJobs,
      };
      setLogs((prev) => ({
        ...prev,
        ...logs,
      }));
      const processStatus = (
        <div className="d-flex w-100 justify-content-between align-items-center">
          <span>{`Reports for #${data.jobNo} - generating (${parseInt(
            data.progress
          )}%)`}</span>
        </div>
      );
      if (authContext.currentUser.id === data.userId) {
        //only if request user is current user
        const toastId = `progress-toast-${data.jobId}`;
        if (!toast.isActive(toastId)) {
          toast.loading(processStatus, {
            ...toastConfig,
            progress: data.progress / 100,
            closeOnClick: false,
            autoClose: false,
            draggable: false,
            toastId,
          });
        } else {
          toast.update(toastId, {
            render: processStatus,
            progress: data.progress / 100,
          });
        }
      }
    });

    eventSource.addEventListener("jobs-init", (e) => {
      const data = JSON.parse(e.data);
      const waitingJobs = getWaitingJobs({ data });
      const activeJobs = getActiveJobs({ data });
      const logs = {
        ...waitingJobs,
        ...activeJobs,
      };
      setLogs((prev) => ({
        ...prev,
        ...logs,
      }));
      const myWaitingJobs = Object.values(waitingJobs).filter(
        (waitingJob) => waitingJob.userId === authContext.currentUser.id
      );
      if (myWaitingJobs.length) {
        //show only for last
        const lastJob = myWaitingJobs.pop();
        toast.info(
          `Job #${lastJob.jobNo} waiting - (position ${lastJob.position})`,
          {
            ...toastConfig,
            hideProgressBar: true,
            autoClose: true,
            toastId: `waiting-toast-${lastJob.jobId}`,
          }
        );
      }
    });

    eventSource.addEventListener("job-completed", (e) => {
      const data = JSON.parse(e.data);
      const waitingJobs = getWaitingJobs({ data, authContext });
      const log = `#${data.jobNo} - ${data.email}`;
      const logs = {
        [data.jobId]: {
          log,
          status: "COMPLETED",
          reportLink: data.reportLink,
        },
        ...waitingJobs,
      };
      setLogs((prev) => ({
        ...prev,
        ...logs,
      }));
      Object.values(waitingJobs)
        .filter(
          (waitingJob) => waitingJob.userId === authContext.currentUser.id
        )
        .forEach((waitingJob) => {
          toast.info(
            `Job #${waitingJob.jobNo} waiting - (position ${waitingJob.position})`,
            {
              ...toastConfig,
              toastId: `waiting-toast-${waitingJob.jobId}`,
            }
          );
        });
      if (authContext.currentUser.id === data.userId) {
        //only if request user is current user
        toast.success(
          <div className="d-flex flex-grow-1 justify-content-between pr-2">
            <span>{`Reports for #${data.jobNo} - ${data.email} - completed.`}</span>
            <a className="ml-2" href={data.reportLink}>
              Download
            </a>
          </div>,
          {
            ...toastConfig,
            closeOnClick: true,
            autoClose: false,
            toastId: "success-toast",
          }
        );
        toast.dismiss(`progress-toast-${data.jobId}`);
      }
    });

    eventSource.addEventListener("job-failed", (e) => {
      const data = JSON.parse(e.data);
      const waitingJobs = getWaitingJobs({ data, authContext });
      const log = `#${data.jobNo} - ${data.email}`;
      const logs = {
        [data.jobId]: {
          log,
          status: "FAILED",
          error: `Generation failed; retrying..`,
        },
        ...waitingJobs,
      };
      setLogs((prev) => ({
        ...prev,
        ...logs,
      }));
      if (authContext.currentUser.id === data.userId) {
        //only if request user is current user
        toast.error(`Failed reports for #${data.jobNo}, retrying..`, {
          ...toastConfig,
          toastId: "error-toast",
        });
        toast.dismiss(`progress-toast-${data.jobId}`);
      }
    });

    // Optional: handle onopen
    eventSource.onopen = () => {
      console.log("SSE connection opened");
    };

    // Optional: handle onerror
    eventSource.onerror = (error) => {
      console.error("SSE error:", error);
      // Decide if you want to close the connection or handle errors differently
    };

    // 3. Cleanup when the component unmounts
    return () => {
      eventSource.close();
    };
  }, [authContext]);

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setSidebarContext({ isBatchModalOpen: false })}
    >
      &times;
    </Button>
  );

  const completedJobs = Object.keys(logs)
    .filter((log) => logs[log].status === "COMPLETED")
    .sort((x, y) => (x.jobId < y.jobId ? -1 : 1));

  const activeJobs = Object.keys(logs)
    .filter((log) => logs[log].status === "IN_PROGRESS")
    .sort((x, y) => (x.jobId < y.jobId ? -1 : 1));

  const waitingJobs = Object.keys(logs)
    .filter((log) => logs[log].status === "WAITING")
    .sort((x, y) => (x.jobId < y.jobId ? -1 : 1));

  const failedJobs = Object.keys(logs)
    .filter((log) => logs[log].status === "FAILED")
    .sort((x, y) => (x.jobId < y.jobId ? -1 : 1));

  return (
    <Modal
      isOpen={sidebarContext.isBatchModalOpen}
      onClosed={() => setSidebarContext({ isBatchModalOpen: false })}
    >
      <ModalHeader close={closeBtn}>Report Generation Progress</ModalHeader>
      <ModalBody>
        <div>
          {Object.values(logs).length ? (
            <>
              {activeJobs.length ? (
                <ListGroup className="mb-2">
                  <ListGroupItem
                    className="d-flex justify-content-center align-items-center py-2 bg-lighter"
                    tag="div"
                  >
                    <span>Currently Generating</span>
                  </ListGroupItem>
                  {activeJobs.map((log, idx) => (
                    <ListGroupItem
                      key={idx}
                      className="d-flex justify-content-between align-items-center py-2"
                      tag="div"
                    >
                      <span className="flex-shrink-0">{logs[log].log}</span>
                      <span className="text-right">{logs[log].progress}</span>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : null}
              {waitingJobs.length ? (
                <ListGroup className="mb-2">
                  <ListGroupItem
                    className="d-flex justify-content-center align-items-center py-2 bg-lighter"
                    tag="div"
                  >
                    <span>Waiting Queue</span>
                  </ListGroupItem>
                  {waitingJobs.map((log, idx) => (
                    <ListGroupItem
                      key={idx}
                      className="d-flex justify-content-start align-items-center py-2"
                      tag="div"
                    >
                      <strong>{logs[log].position}.</strong>
                      <span className="ml-1">{logs[log].log}</span>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : null}
              {completedJobs.length ? (
                <ListGroup className="mb-2">
                  <ListGroupItem
                    className="d-flex justify-content-center align-items-center py-2 bg-lighter"
                    tag="div"
                  >
                    <span>Ready for Download</span>
                  </ListGroupItem>
                  {completedJobs.map((log, idx) => (
                    <ListGroupItem
                      key={idx}
                      className="d-flex justify-content-between align-items-center py-2"
                      tag="div"
                    >
                      <span className="flex-shrink-0">{logs[log].log}</span>
                      <a
                        href={logs[log].reportLink}
                        className="flex-shrink-0 text-right"
                      >
                        Download
                      </a>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : null}
              {failedJobs.length ? (
                <ListGroup className="mb-2">
                  <ListGroupItem
                    className="d-flex justify-content-center align-items-center py-2 bg-lighter"
                    tag="div"
                  >
                    <span>Failed Reports</span>
                  </ListGroupItem>
                  {failedJobs.map((log, idx) => (
                    <ListGroupItem
                      key={idx}
                      className="d-flex justify-content-between align-items-center py-2"
                      tag="div"
                    >
                      <span className="flex-shrink-0">{logs[log].log}</span>
                      <span className="text-right">{logs[log].error}</span>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : null}
            </>
          ) : (
            <span>No reports are being generated</span>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button
          color={"primary"}
          onClick={() => setSidebarContext({ isBatchModalOpen: false })}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BatchPDFGenerationSSE;

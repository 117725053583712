import React from "react";
import Select from "react-select";
import { getArticle } from "../../../../utils/utils";

const OTHER_OPTIONS_MAPPING = {
  manufacturer: "OTHER_MANUFACTURER",
  capacity: "OTHER_CAPACITY",
  craneHoistManufacturer: "OTHER_MANUFACTURER",
  craneHoistCapacity: "OTHER_CAPACITY",
};

function CraneOptionsSelect({
  craneOptions = [],
  value,
  onChange,
  isRequired = false,
  invalid = false,
  name,
  menuPortalTarget = null,
  placeholder,
}) {
  const otherValue = OTHER_OPTIONS_MAPPING[name] || null;

  const options = [
    { value: otherValue, label: "Other" },
    ...craneOptions.map((m) => ({
      value: m.id,
      label: m.name,
    })),
  ];

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),

    control: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  const selectedOption = options.find((opt) => opt.value === value) || null;

  const handleChange = (option) => {
    if (!option) {
      onChange("");
    } else {
      onChange(option.value);
    }
  };

  return (
    <>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        menuPortalTarget={menuPortalTarget}
        isSearchable
        placeholder={`Search for ${getArticle(placeholder)} ${placeholder}..`}
        styles={customStyles}
        menuPosition="fixed"
        isClearable
        required={isRequired}
        name={name}
      />
      {invalid && (
        <span className="text-danger" style={{ fontSize: 12 }}>
          This field is required
        </span>
      )}
    </>
  );
}

export default CraneOptionsSelect;

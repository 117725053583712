import React from "react";
import Routes from "./routes/Routes";
import { AuthProvider } from "./providers/authProvider";
import { WorkOrdersProvider } from "./providers/workOrdersProvider";
import { UsersProvider } from "./providers/usersProvider";
import { CustomersProvider } from "./providers/customersProvider";
import { CranesProvider } from "./providers/cranesProvider";
import { SidebarProvider } from "./providers/sidebarProvider";
import { ScheduleModalProvider } from "./providers/scheduleModalProvider";
import { WorkOrderDetailsProvider } from "./providers/workOrderDetailsProvider";
import { RecurringWorkOrdersProvider } from "./providers/recurringWorkOrdersProvider";
import { EditRecurringWorkOrdersProvider } from "./providers/editRecurringWorkOrdersProvider";
import { InspectionPointsManagerProvider } from "./providers/inspectionPointsManagerProvider";
import { AudtiMergedLocationsProvider } from "./providers/auditMergedLocationsProvider";

import { WorkOrderKPIsProvider } from "./providers/workOrdersKPIsProvider";
import { CraneConfigModalProvider } from "./providers/craneConfigModalProvider";
import { WorkOrderStatusProvider } from "./providers/workOrderStatusProvider";
import { HolidaysProvider } from "./providers/holidaysProvider";
import { CalendarGroupingsProvider } from "./providers/calendarGroupingProvider";
import { ToastContainer } from "react-toastify";

class App extends React.Component {
  render() {
    return (
      <SidebarProvider>
        <ToastContainer />
        <AuthProvider>
          <WorkOrdersProvider>
            <UsersProvider>
              <CustomersProvider>
                <CranesProvider>
                  <ScheduleModalProvider>
                    <WorkOrderDetailsProvider>
                      <RecurringWorkOrdersProvider>
                        <EditRecurringWorkOrdersProvider>
                          <InspectionPointsManagerProvider>
                            <WorkOrderKPIsProvider>
                              <CraneConfigModalProvider>
                                <WorkOrderStatusProvider>
                                  <HolidaysProvider>
                                    <AudtiMergedLocationsProvider>
                                      <CalendarGroupingsProvider>
                                        <Routes />
                                      </CalendarGroupingsProvider>
                                    </AudtiMergedLocationsProvider>
                                  </HolidaysProvider>
                                </WorkOrderStatusProvider>
                              </CraneConfigModalProvider>
                            </WorkOrderKPIsProvider>
                          </InspectionPointsManagerProvider>
                        </EditRecurringWorkOrdersProvider>
                      </RecurringWorkOrdersProvider>
                    </WorkOrderDetailsProvider>
                  </ScheduleModalProvider>
                </CranesProvider>
              </CustomersProvider>
            </UsersProvider>
          </WorkOrdersProvider>
        </AuthProvider>
      </SidebarProvider>
    );
  }
}

export default App;

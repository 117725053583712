import {
  faPen,
  faPlus,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import InformationModal from "../../../../components/InformationModal";
import Loader from "../../../../components/Loader";
import { useAuth } from "../../../../providers/authProvider";
import { commonApi } from "../../../../services/commonServices";
import AddBranchContactInfo from "./AddBranchContactInfo";
import { branchContactInfoApi } from "../../../../services/branchContactInfo";

const BranchContactInfo = () => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState(true);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [refresh, setRefresh] = useState();
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await commonApi.getServiceLocations({
          serviceLocationId: authContext.serviceLocationId,
          pageSize: 9999,
        });

        setServiceLocations(data);
      } catch (error) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error with your request.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authContext.serviceLocationId, refresh]);

  const removeContactPhone = (serviceLocation, index) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          setLoading(true);
          await branchContactInfoApi.updateServiceLocation({
            contactPhone: null,
            id: serviceLocation.id,
            regionId: serviceLocation.regionId,
          });
          setRefresh(!refresh);
          setLoading(false);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Remove Branch Contact Info",
            body: "Phone number removed successfully.",
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setLoading(false);
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Branch Contact Info",
      body: `
      <div style="text-align: center;">
        <p>Are you sure you want to delete this information?</p>
        <p><strong>Phone Number:</strong> ${serviceLocation.contactPhone}</p>
        <p><strong>Location:</strong> ${serviceLocation.name}</p>
      </div>
    `,
    });
  };

  const isDisabledAdd = () => {
    if (!serviceLocations) return true;
    if (authContext.serviceLocationId) {
      const sl = serviceLocations.find(
        (sl) => sl.id === authContext.serviceLocationId
      );
      return !!sl?.contactPhone;
    }

    return serviceLocations.every((sl) => !!sl.contactPhone);
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Branch Contact Info</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
              size="sm"
              className="rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setAddModal(true)}
              disabled={isDisabledAdd()}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader />
          ) : serviceLocations.length &&
            serviceLocations.filter((sl) => sl.contactPhone).length ? (
            <ListGroup flush className="border-top">
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter text-muted small d-flex justify-content-between"
                tag="div"
              >
                <div className="col-5">Contact Phone</div>
                <div className="col-5">Service Location</div>
                <div className="col-2"></div>
              </ListGroupItem>
              {serviceLocations
                .filter((serviceLocation) => serviceLocation?.contactPhone)
                .map((serviceLocation, index) => (
                  <ListGroupItem
                    key={index}
                    className="py-2 border-bottom d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <div className="col-5">{serviceLocation?.contactPhone}</div>
                    <div className="col-5">{serviceLocation?.name}</div>
                    <div className="col-2 d-flex justify-content-end">
                      <Button
                        className="ml-2 rounded d-flex align-items-center"
                        color="none"
                        size="sm"
                        onClick={() => setEditModal(serviceLocation)}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          className="text-warning"
                          size="lg"
                        />
                      </Button>
                      <Button
                        className="ml-2 rounded d-flex align-items-center"
                        color="none"
                        size="sm"
                        onClick={() =>
                          removeContactPhone(serviceLocation, index)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="text-danger"
                          size="lg"
                        />
                      </Button>
                    </div>
                  </ListGroupItem>
                ))}
            </ListGroup>
          ) : (
            <div className="text-center">
              No contact phone numbers have been added
            </div>
          )}
        </CardBody>
      </Card>
      {addModal || editModal ? (
        <AddBranchContactInfo
          submitFunction={branchContactInfoApi.updateServiceLocation}
          onSubmit={() => {
            setAddModal(false);
            setEditModal(false);
            setInformationModal({
              isOpen: true,
              title: "Branch Contact Info",
              body: `${
                addModal ? "Phone number added" : "Phone number edited"
              } successfully.`,
            });
            setRefresh(!refresh);
          }}
          onClose={() => {
            setAddModal(false);
            setEditModal(false);
          }}
          serviceLocation={editModal}
          serviceLocations={serviceLocations}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default BranchContactInfo;

import React, { useReducer, createContext, useContext } from "react";

let reducer = (state, data) => {
  return { ...state, ...data };
};

const initialState = { isOpen: true, isBatchModalOpen: false };

const SidebarContext = createContext(initialState);

const SidebarProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <SidebarContext.Provider value={stateAndDispatch}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);

export { SidebarContext, SidebarProvider };

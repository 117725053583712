import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  InputGroup,
  Form,
} from "reactstrap";
import Loader from "./Loader";

const ConfirmationModalWithInput = ({
  onSubmit,
  onClose,
  title,
  body,
  confirmColor,
  confirmText,
  confirmTextPlaceholder,
  confirmTextType = "text",
  confirmButtonText,
  confirmInputPrepopulate = "",
  cancelButtonText = "Cancel",
  size = "md",
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmInput, setConfirmInput] = useState(confirmInputPrepopulate);

  const doSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit(confirmInput);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose} size={size}>
      <ModalHeader close={closeBtn}>{title}</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <div>
              <p>{body}</p>
              <InputGroup className="col-12" size="m">
                <Input
                  type={confirmTextType}
                  placeholder={confirmTextPlaceholder}
                  required={true}
                  maxLength="50"
                  value={confirmInput || ""}
                  onChange={(evt) => setConfirmInput(evt.target.value)}
                />
              </InputGroup>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            {cancelButtonText}
          </Button>{" "}
          <Button
            disabled={
              loading ||
              (confirmText
                ? (confirmInput || "").toLowerCase() !==
                  (confirmText || "").toLowerCase()
                : !confirmInput?.length)
            }
            color={confirmColor || "primary"}
            type={"submit"}
          >
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ConfirmationModalWithInput;

import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Input,
  Form,
} from "reactstrap";

import { DayPicker } from "react-day-picker";
import moment from "moment";

import {
  faCalendar,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import { otherTimesApi } from "../../services/otherTimesServices";
import EmployeeSelector from "./EmployeeSelector";
import { otherTimeTypesApi } from "../../services/otherTimeTypesServices";

const OTHER_TIME_TYPE_LUNCH = 5;
const SELECTOR_MODE_OTHER = 4;

const AddOtherTimeModal = ({ onSubmit, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateSelectorOpen, setDateSelectorOpen] = useState();
  const [loading, setLoading] = useState();
  const [employee, setEmployee] = useState();
  const [otherTimeTypes, setOtherTimeTypes] = useState([]);

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [timeToAdd, setTimeToAdd] = useState({});
  const [overTimeToAdd, setOverTimeToAdd] = useState({});
  const [timeTypeToAdd, setTimeTypeToAdd] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const doSubmit = async (event) => {
    event.preventDefault();
    const employeeIds = new Set([
      ...Object.keys(timeToAdd).filter((key) => timeToAdd[key]),
      ...Object.keys(overTimeToAdd).filter((key) => overTimeToAdd[key]),
      ...Object.keys(timeTypeToAdd).filter((key) => timeTypeToAdd[key]),
    ]);
    const employees = Array.from(employeeIds).map((employeeId) => ({
      date: moment(selectedDate).format("YYYY-MM-DD"),
      employeeId,
      hours: timeToAdd[employeeId],
      overtimeHours: overTimeToAdd[employeeId],
      otherTimeTypeId: timeTypeToAdd[employeeId],
      note: "Created from the web",
    }));
    if (!employees.length) {
      return setInformationModal({
        isOpen: true,
        title: `Add Time`,
        body: "Add time to at least one employee.",
      });
    }

    setLoading(true);

    try {
      await otherTimesApi.createOtherTimes({
        employees,
      });
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Add Time`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date);
    setDateSelectorOpen(false);
  };

  useEffect(() => {
    setTimeToAdd({});
    return () => setTimeToAdd({});
  }, [selectedDate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await otherTimeTypesApi.getOtherTimeTypes();
        setOtherTimeTypes(result.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body: "There was an error getting the other time types list.",
        });
      }
    };
    if (!otherTimeTypes.length) {
      fetchData();
    }
  }, [otherTimeTypes]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Add Time</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row className="d-flex align-items-center">
                <Label className="col-4 d-flex flex-column text-left mb-0">
                  <span>Date</span>
                  <small className="text-warning">
                    Click the date to{" "}
                    {dateSelectorOpen ? "select/close" : "change"}
                  </small>
                </Label>
                <div className="col-8 d-flex align-items-center justify-content-end">
                  <div className="flex-grow-1">
                    {dateSelectorOpen ? (
                      <DayPicker
                        className="date-picker bg-white rounded border d-flex justify-content-center"
                        mode="single"
                        defaultMonth={selectedDate}
                        selected={[selectedDate]}
                        modifiersClassNames={{
                          selected: "my-selected",
                          today: "my-today",
                          range_start: "my-range_start",
                          range_end: "my-range_end",
                        }}
                        onDayClick={onSelectedDate}
                      />
                    ) : (
                      <div
                        className="float-left d-flex align-items-center px-2 py-1 border rounded bg-white cursor-pointer"
                        onClick={() => setDateSelectorOpen(true)}
                      >
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="mr-2 text-secondary"
                        />
                        <span>{moment(selectedDate).format("MM/DD/YYYY")}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-column col col-12 mt-4">
                  {
                    <EmployeeSelector
                      key={`add-other-time-employee-selector`}
                      employee={employee}
                      mode={SELECTOR_MODE_OTHER}
                      setEmployee={setEmployee}
                    />
                  }
                </div>
              </FormGroup>
            </>
          )}
          {selectedDate && employee ? (
            <ListGroup className="mt-3">
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                tag="div"
              >
                <span>Technician</span>
                <span></span>
              </ListGroupItem>
              {
                <ListGroupItem
                  key={employee.id}
                  className="d-flex justify-content-between align-items-center py-2"
                  tag="div"
                >
                  <span>{`${employee.firstName} ${employee.lastName}`}</span>
                  <div>
                    {employeeEnabled[employee.id] ? (
                      <div className="min-width-250 d-flex align-items-center">
                        <ListGroup className="col-12 px-0">
                          <FontAwesomeIcon
                            onClick={() => {
                              setTimeToAdd({
                                ...timeToAdd,
                                [employee.id]: null,
                              });
                              setOverTimeToAdd({
                                ...overTimeToAdd,
                                [employee.id]: null,
                              });
                              setEmployeeEnabled({
                                ...employeeEnabled,
                                [employee.id]: false,
                              });
                              setTimeTypeToAdd({
                                ...timeTypeToAdd,
                                [employee.id]: null,
                              });
                            }}
                            icon={faTimesCircle}
                            className="mt-2 cursor-pointer text-danger bg-white upper-close"
                          />
                          <ListGroupItem
                            className="d-flex justify-content-center align-items-center py-2"
                            tag="div"
                          >
                            {timeTypeToAdd[employee.id] ===
                            OTHER_TIME_TYPE_LUNCH.toString() ? (
                              <CustomInput
                                required={true}
                                id="regularTime"
                                type="select"
                                name="regularTime"
                                value={timeToAdd[employee.id] || 0.5}
                                onChange={(event) =>
                                  setTimeToAdd({
                                    ...timeToAdd,
                                    [employee.id]: event.currentTarget.value,
                                  })
                                }
                              >
                                <option value={0.5}>0.5</option>
                                <option value={1}>1</option>
                              </CustomInput>
                            ) : (
                              <Input
                                required={true}
                                min={0}
                                max={24}
                                step={0.1}
                                placeholder={"Regular Hours"}
                                type="number"
                                value={
                                  timeTypeToAdd[employee.id] ===
                                  OTHER_TIME_TYPE_LUNCH.toString()
                                    ? 0.5
                                    : timeToAdd[employee.id] || ""
                                }
                                disabled={
                                  timeTypeToAdd[employee.id] ===
                                  OTHER_TIME_TYPE_LUNCH.toString()
                                }
                                onChange={(event) =>
                                  setTimeToAdd({
                                    ...timeToAdd,
                                    [employee.id]: event.currentTarget.value,
                                  })
                                }
                              />
                            )}
                          </ListGroupItem>
                          <ListGroupItem
                            className="d-flex justify-content-center align-items-center py-2"
                            tag="div"
                          >
                            <Input
                              required={true}
                              min={0}
                              max={24}
                              step={0.1}
                              disabled={
                                timeTypeToAdd[employee.id] ===
                                OTHER_TIME_TYPE_LUNCH.toString()
                              }
                              placeholder={"Overtime Hours"}
                              type="number"
                              value={
                                timeTypeToAdd[employee.id] ===
                                OTHER_TIME_TYPE_LUNCH.toString()
                                  ? 0
                                  : overTimeToAdd[employee.id] || ""
                              }
                              onChange={(event) =>
                                setOverTimeToAdd({
                                  ...overTimeToAdd,
                                  [employee.id]: event.currentTarget.value,
                                })
                              }
                            />
                          </ListGroupItem>
                          <ListGroupItem
                            className="d-flex justify-content-center align-items-center py-2"
                            tag="div"
                          >
                            <CustomInput
                              required={true}
                              id="typeSelect"
                              type="select"
                              name="typeSelect"
                              value={timeTypeToAdd[employee.id] || ""}
                              onChange={(event) => {
                                const previousSelectedTimeType =
                                  timeTypeToAdd[employee.id];
                                const selectedTimeType =
                                  event.currentTarget.value;
                                setTimeTypeToAdd({
                                  ...timeTypeToAdd,
                                  [employee.id]: event.currentTarget.value,
                                });

                                const updateTimeValues = (
                                  regularTime,
                                  overTime
                                ) => {
                                  setTimeToAdd((prevTimeToAdd) => ({
                                    ...prevTimeToAdd,
                                    [employee.id]: regularTime,
                                  }));
                                  setOverTimeToAdd((prevOverTimeToAdd) => ({
                                    ...prevOverTimeToAdd,
                                    [employee.id]: overTime,
                                  }));
                                };

                                if (
                                  selectedTimeType ===
                                  OTHER_TIME_TYPE_LUNCH.toString()
                                ) {
                                  updateTimeValues(0.5, 0);
                                } else if (
                                  previousSelectedTimeType ===
                                  OTHER_TIME_TYPE_LUNCH.toString()
                                ) {
                                  updateTimeValues(null, null);
                                }
                              }}
                            >
                              <option value={""}>Select a type</option>
                              {otherTimeTypes.map((type) => {
                                return (
                                  <option key={type.id} value={type.id}>
                                    {type.name}
                                  </option>
                                );
                              })}
                            </CustomInput>
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    ) : (
                      <Button
                        className="rounded"
                        size="sm"
                        color="warning"
                        onClick={() =>
                          setEmployeeEnabled({
                            ...employeeEnabled,
                            [employee.id]: true,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="mr-2 text-white"
                        />
                        Add Time
                      </Button>
                    )}
                  </div>
                </ListGroupItem>
              }
            </ListGroup>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          {loading ? (
            <div className="min-width-250">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"primary"} type={"submit"}>
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddOtherTimeModal;
